import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { ContentfulComponentPlatformFeatureIntro } from '@/../graphql-types';
import Typography from '@/components/layout/Typography';

const Container = styled.div``;

interface ProductImageIntroProps {
  data?: ContentfulComponentPlatformFeatureIntro;
  className?: string;
}
const ProductImageIntro: React.FC<ProductImageIntroProps> = (props) => {
  const { data } = props;
  const imageData = data?.topic?.featureImage;
  const imageAlt = data?.topic?.featureImage?.description;

  return (
    <Container className="mb-lg">
      <div className="container mx-auto text-center">
        {imageData && (
          <GatsbyImage image={getImage(imageData)} alt={imageAlt || ``} />
        )}
      </div>
      <div className="container md:flex gap-x-md items-baseline">
        <Typography
          variant="h2"
          as="h2"
          className="text-primaryMain flex-1 mb-xs"
        >
          {data?.topic?.title}
        </Typography>
        <Typography variant="body" as="p" className="flex-1">
          {data?.topic?.subheading}
        </Typography>
      </div>
    </Container>
  );
};

export default ProductImageIntro;
