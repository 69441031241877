import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageProduct,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper'; //helper for background css on other components
import {
  // Navigation,
  HeroBanner,
  PlatformFeatureLarge,
  PlatformFeatureCards,
  PlatformFeatureSet,
  PlatformCardSet,
  PromoPopUp,
  RegistrationForm,
  ResourceCardSet,
  RichText,
  RichTextColumns,
  SolutionsCardCarousel,
  StatsCloud,
  TabbedFeatures,
  Testimonial,
  TextBlock,
  FooterCta,
  Undefined,
} from '@/components/contentful/components';
import ProductImageIntro from '@/components/contentful/components/ProductImageIntro';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageProductProps {
  data?: ContentfulPageProduct;
}
const PageProduct: React.FC<PageProductProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {/* {data?.subNav && (
        <Navigation
          data={data?.subNav}
          currentPage={data?.compose__page?.[0]?.id}
        />
      )} */}
      {data?.heroBanner &&
        wrapBackground(
          data?.heroBanner,
          <HeroBanner data={data?.heroBanner as ContentfulEntry} />,
        )}

      {data?.intro && (
        <>
          {data?.intro?.internal?.type === 'ContentfulComponentTextBlock' && (
            <TextBlock data={data.intro} />
          )}
          {data?.intro?.internal?.type ===
            'ContentfulComponentPlatformFeatureIntro' &&
            wrapBackground(
              data?.intro,
              <ProductImageIntro data={data?.intro as ContentfulEntry} />,
            )}
        </>
      )}

      {/* {data?.intro &&
        wrapBackground(
          data?.intro,
          <ProductImageIntro data={data?.intro as ContentfulEntry} />,
        )} */}
      {data?.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;
        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_TABBED_FEATURES:
            element = <TabbedFeatures key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_LARGE:
            element = (
              <PlatformFeatureLarge key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_CARDS:
            element = (
              <PlatformFeatureCards key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_SET:
            element = (
              <PlatformFeatureSet key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PLATFORM_CARD_SET:
            element = <PlatformCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PROMO_POPUP:
            element = <PromoPopUp key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_FORM:
            element = <RegistrationForm key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT:
            element = <RichText key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RICH_TEXT_COLUMNS:
            element = <RichTextColumns key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_SOLUTIONS_CAROUSEL:
            element = (
              <SolutionsCardCarousel key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_STATS_CLOUD:
            element = <StatsCloud key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_TESTIMONIAL:
            element = <Testimonial key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }
        return wrapBackground(contentfulComponent, element);
      })}
      {data.footerCta && <FooterCta data={data.footerCta} />}
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageProduct;
